import React from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {Collapse, Container, Nav, Navbar, NavbarBrand,} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faArchive, faTerminal, faUsers} from "@fortawesome/free-solid-svg-icons";

function ScrollTransparentNavbar() {
    const [collapseOpen, setCollapseOpen] = React.useState(false);

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar id={"navigation"} className={"pb-1 fixed-top bg-white"} color="black" expand="lg">
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand to="/" tag={Link} id="navbar-brand">
                            <img src={require("../../assets/img/brand/Spark61.svg").default} className={"logo"}
                                 alt={"Logo"}/>
                            <span className={"text-dark"}>Spark61.dev</span>
                        </NavbarBrand>
                        <button
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            className="navbar-toggler"
                        >
                            <span className="navbar-toggler-bar top-bar"/>
                            <span className="navbar-toggler-bar middle-bar"/>
                            <span className="navbar-toggler-bar bottom-bar"/>
                        </button>
                    </div>
                    <Collapse isOpen={collapseOpen} navbar>
                        <Nav className="ml-auto" id="ceva" navbar>
                            <li className="dropdown nav-item">
                                <a href="/#projects"
                                   className="nav-link text-dark">
                                    <FontAwesomeIcon icon={faArchive} size={"lg"} className={"mr-1"}/>
                                    <p>Meine Projekte</p>
                                </a>
                            </li>
                            <li className="dropdown nav-item">
                                <a href="/#teams"
                                   className="nav-link text-dark">
                                    <FontAwesomeIcon icon={faUsers} size={"lg"} className={"mr-1"}/>
                                    <p>Teams</p>
                                </a>
                            </li>
                            <li className="dropdown nav-item text-dark">
                                <a href="/#skills"
                                   className="nav-link text-dark">
                                    <FontAwesomeIcon icon={faTerminal} size={"lg"} className={"mr-1"}/>
                                    <p>Kenntnisse</p>
                                </a>
                            </li>
                            <li className="dropdown nav-item">
                                <a href="/#social_media"
                                   className="nav-link text-dark">
                                    <FontAwesomeIcon icon={faTwitter} size={"lg"} className={"mr-1"}/>
                                    <p>Social Media</p>
                                </a>
                            </li>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default ScrollTransparentNavbar;
