/*eslint-disable*/
import React from "react";

// reactstrap components

let scrollListener = undefined;

// core components

function PresentationHeader() {
    //if the image is visible
    const [loaded, setLoaded] = React.useState(true);

    const height = window.innerHeight;

    React.useEffect(function () {
        if (scrollListener) {
            document.removeEventListener("scroll", scrollListener);
        }

        scrollListener = function (e) {
            const y = window.scrollY;

            if (loaded) {
                //loaded

                if ((height * 2) < y) {
                    setLoaded(false)
                    document.getElementById("text").style.transform = "translateY(0px)";

                } else {
                    document.getElementById("text").style.transform = "translateY(" + y + "px)";
                }
            } else {
                //unloaded

                if ((height * 1.8) > y) {
                    setLoaded(true)
                }

            }
        };

        document.addEventListener('scroll', scrollListener)
    }, [loaded])

    return (
        <>
            <div className="page-header clear-filter">
                <div className={(loaded ? "position-fixed" : "") + " w-100"}>

                    <div data-rellax-speed="-4">
                        <div
                            className={"page-header-image w-100 h-100 sky-image " + (loaded ? "position-fixed" : "")}
                            style={{
                                backgroundImage:
                                    "url(" +
                                    require("assets/img/presentation-page/new/sky.png")
                                         +
                                    ")",
                            }}
                        />
                    </div>
                    <div
                        className={"rellax-header rellax-header-buildings w-100 h-100 " + (loaded ? "position-fixed" : "")}
                        data-rellax-speed="0"
                    >
                        <div
                            className={"page-header-image page-header-city"}
                            style={{
                                backgroundImage:
                                    "url(" +
                                    require("assets/img/presentation-page/new/haus.png")
                                         +
                                    ")",
                            }}
                        />
                    </div>
                    <div className="rellax-text-container">
                        <h1 className="h1-seo mt-5 px-2" id={"text"}
                            data-rellax-speed="-1">
                            Spark61
                        </h1>

                    </div>
                </div>
            </div>

            <div style={{backgroundColor: "#83c1d8", zIndex: -1, top: 0}}
                 className={"h-100 w-100 homepage-header-background " + (loaded ? "position-fixed " : "")}/>
        </>
    );
}

export default PresentationHeader;