import React from "react";
import {Button, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {useSessionParameter} from "./Utils";


function Cookie() {
    const [hidden, setHidden] = useSessionParameter("cookies_accepted", true, false);

    if (hidden) return <></>

    return <>
        <div className={"bgt-white cookie text-center w-50"} style={{borderRadius: "100px"}}>
            Magst du Cookies? <img className={"svg-small"}
                                   src={require("assets/img/Cookie.svg").default}
                                   alt={"Cookie"} style={{width: "25px"}}/>

            <FormGroup style={{marginTop: "-20px"}} classNames={"mb-3"} check>
                <Label check>
                    <Input type="checkbox" checked disabled/>
                    <span className="form-check-sign"/>
                    Erforderlich
                </Label>
            </FormGroup>
            <Row className={"justify-content-center"} style={{marginTop: "-20px"}}>
                <Col xs={2}>
                    <Button onClick={() => setHidden(true)}>Akzeptieren</Button>
                </Col>
            </Row>
        </div>
    </>
}

export default Cookie;