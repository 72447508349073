import React from "react";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGithub, faGitlab, faInstagram, faXTwitter} from "@fortawesome/free-brands-svg-icons";

// core components

function SocialMedia() {

    return (
        <>
            <div
                className="bg-white text-dark"
            >
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="text-center title" id="social_media">
                                Social Media
                            </h2>
                            <div className="text-center ml-auto mr-auto d-table" style={{
                                borderSpacing: "10px"
                            }}>
                                <Button
                                    className="btn-icon btn-round d-table-cell"
                                    color="twitter"
                                    href={'https://twitter.com/YTSpark61'}
                                >
                                    <FontAwesomeIcon icon={faXTwitter} size={"lg"} />
                                </Button>
                                <Button
                                    className="btn-round btn-icon d-table-cell"
                                    color="instagram"
                                    href={'https://www.instagram.com/spark061/'}
                                >
                                    <FontAwesomeIcon icon={faInstagram} size={"lg"} />
                                </Button>
                                <Button
                                    className="btn-round btn-icon d-table-cell"
                                    color="gitlab"
                                    href={'https://gitlab.zyonicsoftware.com/Spark61'}
                                >
                                    <FontAwesomeIcon icon={faGitlab} size={"lg"} />
                                </Button>
                                <Button
                                    className="btn-round btn-icon d-table-cell"
                                    color="github"
                                    href="https://github.com/Spark61"
                                    target="_blank"
                                >
                                    <FontAwesomeIcon icon={faGithub} size={"lg"}  />
                                </Button>
                            </div>
                            <div className="space-50"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default SocialMedia;