import React from "react";
import {Link} from "react-router-dom";
// reactstrap components
import {Collapse, Container, Nav, Navbar, NavbarBrand,} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";
import {faArchive, faTerminal, faUsers} from "@fortawesome/free-solid-svg-icons";

function ScrollTransparentNavbar() {
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    const [navbarColor, setNavbarColor] = React.useState(
        (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
            ? ""
            : " navbar-transparent"
    );

    React.useEffect(() => {
        const updateNavbarColor = () => {
            if (
                document.documentElement.scrollTop > 499 ||
                document.body.scrollTop > 499
            ) {
                setNavbarColor("");
            } else if (
                document.documentElement.scrollTop < 500 ||
                document.body.scrollTop < 500
            ) {
                setNavbarColor(" navbar-transparent");
            }
        };
        window.addEventListener("scroll", updateNavbarColor);
        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    });

    function getOffset(el) {
        let _x = 0;
        let _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return {top: _y, left: _x};
    }

    const scrollTo = (e, id) => {
        e.preventDefault();

        const element = document.getElementById(id);
        const navigation = document.getElementById("navigation");

        window.scroll({
            top: getOffset(element).top - navigation.clientHeight,
            behavior: 'smooth'
        });
    };

    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}
            <Navbar id={"navigation"} className={"pb-1 fixed-top" + navbarColor} color="white" expand="lg">
                <Container>
                    <div className="navbar-translate">
                        <NavbarBrand to="/" tag={Link} id="navbar-brand">
                            <img src={require("../../assets/img/brand/Spark61.svg").default} className={"logo"}
                                 alt={"Logo"}/>
                            Spark61.dev
                        </NavbarBrand>
                        <button
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            className="navbar-toggler"
                        >
                            <span className="navbar-toggler-bar top-bar"/>
                            <span className="navbar-toggler-bar middle-bar"/>
                            <span className="navbar-toggler-bar bottom-bar"/>
                        </button>
                    </div>
                    <Collapse isOpen={collapseOpen} navbar>
                        <Nav className="ml-auto" id="ceva" navbar>
                            <li className="dropdown nav-item">
                                <a href="#projects"
                                   onClick={(e) => scrollTo(e, "projects")}
                                   className="nav-link">
                                    <FontAwesomeIcon icon={faArchive} size={"lg"} className={"mr-1"}/>
                                    <p>Meine Projekte</p>
                                </a>
                            </li>
                            <li className="dropdown nav-item">
                                <a href="#teams"
                                   onClick={(e) => scrollTo(e, "teams")}
                                   className="nav-link">
                                    <FontAwesomeIcon icon={faUsers} size={"lg"} className={"mr-1"}/>
                                    <p>Teams</p>
                                </a>
                            </li>
                            <li className="dropdown nav-item">
                                <a href="#skills"
                                   onClick={(e) => scrollTo(e, "skills")}
                                   className="nav-link">
                                    <FontAwesomeIcon icon={faTerminal} size={"lg"} className={"mr-1"}/>
                                    <p>Kenntnisse</p>
                                </a>
                            </li>
                            <li className="dropdown nav-item">
                                <a href="#social_media"
                                   onClick={(e) => scrollTo(e, "social_media")}
                                   className="nav-link">
                                    <FontAwesomeIcon icon={faTwitter} size={"lg"} className={"mr-1"}/>
                                    <p>Social Media</p>
                                </a>
                            </li>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    );
}

export default ScrollTransparentNavbar;
