import {useEffect, useState} from "react";

export function useUrlParameter(param, automaticallyAdd = true, defaultValue = "") {
    const url = new URLSearchParams(window.location.search);
    const [value, setValue] = useState(url.get(param));

    useEffect(() => {
        if (!value && automaticallyAdd) {
            url.set(param, defaultValue);
            window.history.pushState(null, null, "?" + url.toString());

            setValue(defaultValue);
        }
    }, [value, automaticallyAdd, defaultValue, param]);

    return [!value && automaticallyAdd ? defaultValue : value, function (value) {
        url.set(param, value);
        window.history.pushState(null, null, "?" + url.toString());

        setValue(value);
    }]
}

export function useSessionParameter(param, automaticallyAdd = true, defaultValue = "", sessionStorage = window.localStorage) {
    const tempValue = sessionStorage.getItem(param);

    const [value, setValue] = useState(convertToType(defaultValue, tempValue));

    useEffect(() => {
        if (!value && typeof value !== "boolean" && automaticallyAdd) {
            sessionStorage.setItem(param, defaultValue);

            setValue(defaultValue);
        }
    }, [value, automaticallyAdd, defaultValue, param, sessionStorage]);

    return [!value && typeof value !== "boolean" && automaticallyAdd ? defaultValue : value, function (value) {
        sessionStorage.setItem(param, value);

        setValue(value);
    }]
}

function convertToType(t, e) {
    if (typeof t === "boolean") {
        return e === "true"
    }

    return (t.constructor)(e);
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    useUrlParameter,
    useSessionParameter
}