import React from "react";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";
import SlideIn from "../../../components/SlideIn";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faDiscord, faInstagram, faTwitter} from "@fortawesome/free-brands-svg-icons";

// core components

function SVStudiosTeam() {

    return (
        <>
            <SlideIn className="bg-white position-relative">
                <div className="section section-content">
                    <Container>
                        <Row>
                            <Col className="ml-auto mr-auto" md="5">
                                <div className="section-description">
                                    <h2 className="title">SV-Studios</h2>
                                    <h6 className="category">Administrator</h6>
                                    <h5 className="description text-justify">
                                        Der Discord Server „SV-Studios“ hat einen Discord Bot erstellt, der auf anderen
                                        Discord Servern durch seine zahlreichen und hochqualitativen Funktionen
                                        ausgezeichnet für Struktur, Moderations- und Setupmöglichkeiten sorgt.
                                        Dieser Discord Bot ist kostenlos, aber allerdings daran gebunden, dass der
                                        Discord
                                        Server, wo der Bot eingeladen werden soll über 150 aktive Nutzer/innen verfügt.
                                        Auch
                                        ein öffentlicher Bot, der nur über eingeschränkte Features verfügt ist
                                        vorhanden,
                                        der an keine Anforderungen gebunden ist.
                                    </h5>
                                </div>
                                <div>
                                    <Button color={"blue"} href={"https://sv-studios.net/"}>
                                        <FontAwesomeIcon icon={faHome}/>
                                    </Button>
                                    <Button color={"blue"} href={"https://sv-studios.net/discord.html"}>
                                        <FontAwesomeIcon icon={faDiscord}/>
                                    </Button>
                                    <Button color={"blue"} href={"https://sv-studios.net/discord.html"}>
                                        <FontAwesomeIcon icon={faTwitter}/>
                                    </Button>
                                    <Button color={"blue"} href={"https://twitter.com/SVStudiosNET"}>
                                        <FontAwesomeIcon icon={faInstagram}/>
                                    </Button>
                                </div>
                            </Col>
                            <Col md="5">
                                <div className="image-container">

                                    <img
                                        alt="..."
                                        className="img"
                                        data-rellax-speed="4"
                                        src={
                                            require("assets/img/brand/SVStudios.svg").default
                                        }
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </SlideIn>
        </>
    );
}

export default SVStudiosTeam;
