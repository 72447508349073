import React from "react";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";

// core components

function Projects() {

    function isDarkMode() {
        if (localStorage.getItem("darkmode")) return localStorage.getItem("darkmode") === "true";
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    return (
        <>

            <svg className={"w-100 position-relative"} id="visual"
                 viewBox="0 0 900 50" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 28L11.5 27C23 26 46 24 69 26C92 28 115 34 138.2 31.5C161.3 29 184.7 18 207.8 12C231 6 254 5 277 8.8C300 12.7 323 21.3 346 25.8C369 30.3 392 30.7 415.2 27.5C438.3 24.3 461.7 17.7 484.8 18C508 18.3 531 25.7 554 28.5C577 31.3 600 29.7 623 25C646 20.3 669 12.7 692.2 16C715.3 19.3 738.7 33.7 761.8 35.3C785 37 808 26 831 18.3C854 10.7 877 6.3 888.5 4.2L900 2L900 51L888.5 51C877 51 854 51 831 51C808 51 785 51 761.8 51C738.7 51 715.3 51 692.2 51C669 51 646 51 623 51C600 51 577 51 554 51C531 51 508 51 484.8 51C461.7 51 438.3 51 415.2 51C392 51 369 51 346 51C323 51 300 51 277 51C254 51 231 51 207.8 51C184.7 51 161.3 51 138.2 51C115 51 92 51 69 51C46 51 23 51 11.5 51L0 51Z"
                    fill={isDarkMode() ? "#02101ecc" : "#031425cc"}/>
            </svg>
            <div
                className="section section-components"
                data-background-color="dark-blue"
            >
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">
                            <h2 className="text-center title" id={"projects"}>
                                Meine Projekte
                            </h2>
                            <div className="text-center ml-auto mr-auto">
                                <Row>
                                    <Col lg={6}>
                                        <h4>Image-Server</h4>
                                        <p className="description">
                                            Ein Image-Server, der Bilder auf einem Server speichert und diese dann
                                            zur Verfügung stellt.
                                        </p>
                                        <Row className={"justify-content-center"}>
                                            <div className={"mr-1"}>
                                                <Button color={"blue"} href={"https://image.sv-studios.net"}
                                                        target="_blank">Zum
                                                    Dienst</Button>
                                            </div>
                                            <div className={"ml-1"}>
                                                <Button>Mehr Erfahren</Button>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        <h4>Url-Shorter</h4>
                                        <p className="description">
                                            Sie haben einen zu langen Link? Mit unserem URL-Kürzer können sie diesen
                                            ganz
                                            einfach komprimieren.
                                        </p>
                                        <Row className={"justify-content-center"}>
                                            <div className={"mr-1"}>
                                                <Button color={"blue"} href={"https://url.sv-studios.net"}
                                                        target="_blank">Zum
                                                    Dienst</Button>
                                            </div>
                                            <div className={"ml-1"}>
                                                <Button>Mehr Erfahren</Button>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        <h4>CStats</h4>
                                        <p className="description">
                                            Mit CStats können Sie aufzeichnen wie lange Sie an welchem
                                            Programmierprojekt
                                            gearbeitet haben.
                                        </p>
                                        <Row className={"justify-content-center"}>
                                            <div className={"mr-1"}>
                                                <Button color={"blue"} href={"https://cstats.sv-studios.net"}
                                                        target="_blank">Zum
                                                    Dienst</Button>
                                            </div>
                                            <div className={"ml-1"}>
                                                <Button>Mehr Erfahren</Button>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col lg={6}>
                                        <h4>Structure-Bot</h4>
                                        <p className="description">
                                            Unser Structure-Bot ist ein flexibler Discord-Bot der Fast jeder Aufgabe
                                            nachkommen kann.
                                        </p>
                                        <Row className={"justify-content-center"}>
                                            <div className={"mr-1"}>
                                                <Button color={"blue"} href={"https://dsc.gg/svbot"} target="_blank">Zum
                                                    Dienst</Button>
                                            </div>
                                            <div className={"ml-1"}>
                                                <Button>Mehr Erfahren</Button>
                                            </div>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                            <div className="space-50"/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Projects;
