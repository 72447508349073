/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route} from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
import Homepage from "views/Homepage.js";
import Privacy from "./views/Privacy";
import Imprint from "./views/Imprint";
import {Navigate, Routes} from "react-router";

// others

function isDarkMode() {
    if (localStorage.getItem("darkmode")) return localStorage.getItem("darkmode") === "true";
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
}

if (isDarkMode()) {
    require("assets/css/dark-theme.css");
} else {
    require("assets/css/white-theme.css");
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <BrowserRouter>
        <Routes>
            <Route
                path="/impressum"
                element={<Imprint />}
            />
            <Route
                path="/datenschutz"
                element={<Privacy />}
            />
            <Route
                path="/"
                element={<Homepage />}
            />

            <Route path="*" element={<Navigate to="/" replace/> }/>
        </Routes>
    </BrowserRouter>
);
