import React from "react";

// reactstrap components
import SVStudiosTeam from "./team/SVStudiosTeam";
import ZyonicSoftwareTeam from "./team/ZyonicSoftwareTeam";

// core components

function Teams() {
    return (
        <>
            <div className={"bg-white"}>
                <h2 className={"bg-white position-relative text-center title mb-0"} id={"teams"}>Teams</h2>
                <ZyonicSoftwareTeam/>
                <SVStudiosTeam/>
            </div>
        </>
    );
}

export default Teams;
