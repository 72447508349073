import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import PresentationHeader from "components/Headers/PresentationHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";

// sections for this page
import Projects from "./presentation-sections/Projects";
import Teams from "./presentation-sections/Teams";
import "assets/demo/demo.css?v=1.5.0";
import Skills from "./presentation-sections/Skills";
import SocialMedia from "./presentation-sections/SocialMedia";
import Cookie from "../components/Cookie";

function Homepage() {
    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }
    });
    React.useEffect(() => {
        let hash = window.location.hash;
        if (hash) {
            let element = document.getElementById(hash.substring(1));
            if (element) {

                const navigation = document.getElementById("navigation");

                window.scroll({
                    top: getOffset(element).top - navigation.clientHeight,
                    behavior: 'smooth'
                });
            }
        }

        function getOffset(el) {
            let _x = 0;
            let _y = 0;
            while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
                _x += el.offsetLeft - el.scrollLeft;
                _y += el.offsetTop - el.scrollTop;
                el = el.offsetParent;
            }
            return {top: _y, left: _x};
        }
    }, []);


    //TODO andere Bildschirmgrößen

    return (
        <>
            <ScrollTransparentNavbar/>
            <Cookie/>
            <div className="wrapper">
                <PresentationHeader/>
                <Projects/>
                <Teams/>
                <Skills/>
                <SocialMedia/>

                <FooterBlack/>
            </div>
        </>
    );
}

export default Homepage;
