import React from "react";

// reactstrap components
import {Col, Container, Row} from "reactstrap";
import Java from "assets/img/brand/Java.svg";
// core components

function Components() {
    return (
        <>
            <div
                className="section section-components w-100 pt-0 pb-0"
                data-background-color="dark-blue"
            >
                <div className={"darker"} style={{
                    /* The image used */
                    backgroundImage: "url(" +
                        require("assets/img/code2.jpg")
                            .default +
                        ")",


                    /* Full height */
                    height: "100%",
                    width: "100%",
                    position: "absolute",


                    /* Create the parallax scrolling effect */
                    backgroundAttachment: "fixed",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover"
                }}/>
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" md="8">


                            {/*Bootstrap Framework*/}
                            {/*coding bild aus hintergrund, mit paralaxeffekt*/}

                            <h2 className="text-center title" id="skills">
                                Kenntnisse
                            </h2>
                            <Row>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={Java}
                                             alt={"Java"}/>                                        <h5>Java</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/JavaScript.svg").default}
                                             alt={"JavaScript"}/>
                                        <h5>JavaScript</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/PHP.svg").default}
                                             alt={"PHP"}/>                                        <h5>PHP</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/HTML.svg").default}
                                             alt={"HTML"}/>                                        <h5>HTML</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/CSS.svg").default}
                                             alt={"CSS"}/>                                        <h5>CSS</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/Postgres.svg").default}
                                             alt={"SQL"}/>
                                        <h5>SQL</h5>
                                    </div>
                                </Col>
                            </Row>

                            <h2 className="text-center title">
                                Frameworks
                            </h2>
                            <Row>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/React.svg").default}
                                             alt={"React"}/>
                                        <h5>React</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/Spring.svg").default}
                                             alt={"Spring"}/>
                                        <h5>Spring</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/Bootstrap.svg").default}
                                             alt={"Bootstrap"}/>
                                        <h5>Bootstrap</h5>
                                    </div>
                                </Col>

                            </Row>

                            <h2 className="text-center title">
                                IDEs
                            </h2>
                            <Row>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/jetbrains/IntelliJ_IDEA_icon.svg").default}
                                             alt={"IntelliJ IDEA"}/>
                                        <h5>IntelliJ IDEA</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/jetbrains/WebStorm_icon.svg").default}
                                             alt={"WebStorm"}/>
                                        <h5>WebStorm</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/jetbrains/PhpStorm_icon.svg").default}
                                             alt={"PhpStorm"}/>
                                        <h5>PhpStorm</h5>
                                    </div>
                                </Col>
                                <Col>
                                    <div className={"text-center"}>
                                        <img className={"svg-small svg-logo-height"}
                                             src={require("assets/img/brand/jetbrains/DataGrip_icon.svg").default}
                                             alt={"DataGrip"}/>
                                        <h5>DataGrip</h5>
                                    </div>
                                </Col>
                            </Row>


                        </Col>
                    </Row>
                </Container>
                <div className="space-50"/>
            </div>
        </>
    );
}

export default Components;
