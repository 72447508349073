/*eslint-disable*/
import React from "react";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faMoon, faSun} from "@fortawesome/free-solid-svg-icons";

// reactstrap components

// core components

function FooterBlack() {
    function isDarkMode() {
        if (localStorage.getItem("darkmode")) return localStorage.getItem("darkmode") === "true";
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }

    function switchDarkMode() {
        localStorage.setItem("darkmode", !isDarkMode());
        window.location.reload();
    }

    return (
        <>
            <footer className="footer" data-background-color="black">
                <div className="copyright float-none" id="copyright">
                    <ul>
                        <li><a href={"/impressum"}>Impressum</a></li>
                        <li><a href={"/datenschutz"}>Datenschutz</a></li>
                    </ul>
                    {isDarkMode() ?
                        <>
                            <Button color={"outline-light"} onClick={switchDarkMode}><FontAwesomeIcon
                                icon={faMoon}
                                size={"1x"}/> Darkmode</Button>
                        </> :
                        <>
                            <Button color={"outline-light"} onClick={switchDarkMode}><FontAwesomeIcon icon={faSun}
                                                                                                      size={"1x"}/> Whitemode</Button>
                        </>
                    }
                    <br/>

                    © {new Date().getFullYear()} Spark61 Lizenziert von {" "}
                    <a
                        href="https://zyonicsoftware.com"
                        target="_blank"
                    >
                        ZyonicSoftware
                    </a>
                </div>
            </footer>
        </>
    );
}

export default FooterBlack;
