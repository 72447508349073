import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
// core components
import FooterBlack from "components/Footers/FooterBlack.js";

// sections for this page
import "assets/demo/demo.css?v=1.5.0";
import Navbar from "../components/Navbars/Navbar";
import Cookie from "../components/Cookie";

function Imprint() {
    React.useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // initialise Rellax for this page
        if (window.innerWidth >= 991) {
            setTimeout(function () {
                new Rellax(".rellax", {
                    center: true,
                });
            }, 5000);
            new Rellax(".rellax-header");
            new Rellax(".rellax-text");
        }
    });


    return (
        <>
            <Navbar/>
            <Cookie/>
            <div className="wrapper text-center  imprint pd-5 text-dark mt-5 pt-5">
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Jan Niklas Fischer<br/>
                    Roitzscher Stra&szlig;e 13<br/>
                    06808 Bitterfeld-Wolfen</p>

                <h2>Kontakt</h2>
                <p>E-Mail: <a href={"mailto:info@spark61.dev"}>info@spark61.dev</a></p>

                <h2>Bildnachweise</h2>
                <p>Copyright © {new Date().getFullYear()} JetBrains s.r.o. IntelliJ IDEA and the IntelliJ IDEA logo are
                    registered trademarks of JetBrains s.r.o.<br/>
                    Copyright © {new Date().getFullYear()} JetBrains s.r.o. WebStorm and the WebStorm logo are
                    registered trademarks of JetBrains s.r.o.<br/>
                    Copyright © {new Date().getFullYear()} JetBrains s.r.o. PhpStorm and the PhpStorm logo are
                    registered trademarks of JetBrains s.r.o.<br/>
                    Copyright © {new Date().getFullYear()} JetBrains s.r.o. DataGrip and the DataGrip logo are
                    registered trademarks of JetBrains s.r.o.<br/>
                    Photo by <a
                        href="https://unsplash.com/@codestorm?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Safar
                        Safarov</a> on <a
                        href="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a><br/>

                    Photo by <a href={"https://www.behance.net/gallery/41692141/FREE-BAKERY-ICONS"}>AtomAm</a> on <a
                        href="https://www.stockio.com/free-icon/bakery-icons-cookie">stockio</a><br/>
                    Photo by <a
                        href="https://unsplash.com/@rohan_gangopadhyay?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Rohan
                        Gangopadhyay</a> on <a
                        href="https://unsplash.com/s/photos/chicago-skyline?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">Unsplash</a>


                </p>


                <p>Quelle: <a
                    href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
                </p>
                <FooterBlack/>
            </div>
        </>
    )
        ;
}

export default Imprint;
