import React from "react";

// reactstrap components
import {Button, Col, Container, Row} from "reactstrap";
import SlideIn from "../../../components/SlideIn";

// core components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faTwitter} from "@fortawesome/free-brands-svg-icons";

function ZyonicSoftwareTeam() {

    return (
        <>
            <SlideIn left={false} className="bg-white position-relative">
                <div className="section section-content">
                    <Container>
                        <Row>
                            <Col md="5">
                                <div className="image-container">
                                    <img
                                        alt="..."
                                        className="img "
                                        data-rellax-speed="4"
                                        src={
                                            require("assets/img/brand/ZyonicSoftware.png")
                                        }
                                    />
                                </div>
                            </Col>
                            <Col className="ml-auto mr-auto" md="5">
                                <div className="section-description">
                                    <h2 className="title">Zyonic Software</h2>
                                    <h6 className="category">Projekt Management</h6>
                                    <h5 className="description text-justify">
                                        Wir von Zyonic versuchen immer, die Standards weiterzutreiben. Unsere
                                        verschiedenen Teams geben ihr Bestes, um Ihre Erfahrungen mit unseren Produkten
                                        so nahtlos und positiv wie möglich zu gestalten.

                                        Von einer kleinen Portfolioseite über Minecraft-Plugins bis hin zu einer
                                        vollständig maßgeschneiderten Softwarelösung kann unser engagiertes Team von
                                        Entwicklern fast jeden Ihrer Wünsche in die Tat umsetzen.

                                        Darüber hinaus spezialisieren wir uns darauf, Software für Content-Creator zu
                                        planen, entwickeln und die letztendliche Verwendung zu organisieren.
                                    </h5>
                                </div>
                                <div>
                                    <Button color={"zyonic-red"} href={"https://zyonicsoftware.com"}>
                                        <FontAwesomeIcon icon={faHome}/>
                                    </Button>
                                    <Button color={"zyonic-blue"} href={"https://twitter.com/ZyonicSoftware"}>
                                        <FontAwesomeIcon icon={faTwitter}/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </SlideIn>
        </>
    );
}

export default ZyonicSoftwareTeam;
