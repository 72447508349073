import React from "react";
import PropTypes from "prop-types";

// reactstrap components

function SlideIn(prop) {
    const id = generateString(20);

    React.useEffect(function () {
        const element = window.document.getElementById(id);
        const classes = element.classList;

        const height = element.getBoundingClientRect().top;
        classes.add("invisible")

        const listener = function (e) {
            const y = window.scrollY;

            if (y > height * 0.7) {
                classes.remove("invisible");
                classes.add(prop.left ? "slide-in-left" : "slide-in-right");
            }
        };

        document.addEventListener('scroll', listener);

        listener();
    }, []);

    return <>
        <div className={prop.className}>
            <div id={id}>
                {prop.children}
            </div>
        </div>
    </>
}

SlideIn.propTypes = {
    left: PropTypes.bool,
    className: PropTypes.string,
    perStep: PropTypes.number,
};

SlideIn.defaultProps = {
    left: true,
    className: "",
}

function generateString(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export default SlideIn;
